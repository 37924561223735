
<script>
import { storeComputed, storeMethods } from "@/store/store-helper";
import { walletComputed, walletMethods } from "@/store/wallet-helper";
import { marketComputed, marketMethods } from "@/store/market-helper";
// Smart contract implementations
// ----------------------------------------
import { BigNumber } from "@ethersproject/bignumber";
import { ethers } from "ethers";
let { utils } = ethers;
import { contractAddress, erc20Artifact, crowdsaleArtifact, farmArtifact } from "@/contracts";
import { toPlain } from "@/utils/object-util.js";
import Swal from 'sweetalert2';

import ProjectConstants from '@/config/constants.js'
const log = require('debug')('app:presale');
let presaleEndDate = ProjectConstants.schedulePresaleEnd;

const ALL_1 = 0.1;
// ----------------------------------------
const Big = require('big.js');

function toBigDec(n) {
  let s = new Big(n).toFixed();
  return ethers.utils.parseEther(s);
}

export default {
  setup() {
  },
  computed: {
    ...storeComputed,
    ...walletComputed,
    ...marketComputed,

    endOfSale() {
      let vm = this;
      if (Date.now() > presaleEndDate.getTime()) return true;
      if (vm.roundNo > 1 && vm.roundNo > vm.maxRound) return true;
      return false;
    }
  },
  watch: {
    walletAddress() {
      if (this.ethereumDetected && this.mounted) {
        log('+++++++++++ watch updateTokenBalance() at %s', this.walletAddress)
        this.updateTokenBalance();
      }
    },
    walletChainId() {
      if (this.ethereumDetected && this.mounted) {
        this.initCrowdsale();
      }
    },
    tetherAmount() {
      if (this.focusAt == "coin") {
        let amt = this.tetherAmount / this.tokenRatio;
        this.tokenAmount = Math.floor(amt * 100) / 100;
      }
    },
    tokenAmount() {
      if (this.focusAt == "token") {
        this.tetherAmount = this.tokenAmount * this.tokenRatio;
      }
    },
  },
  unmounted() {
    this.setSpin(false);
  },
  data() {
    return {
      mounted: false,

      stakeTokenBalance: 0,
      rewardTokenBalance: 0,
      tetherBalance: 0,
      stakeToken: null,
      rewardToken: null,
      crowdSale: null,
      miningFarm: null,

      refAddress: null,

      buyModal: false,
      tetherAmount: 0,
      tokenAmount: 0,
      purchasedAmount: 0,
      focusAt: "",

      permitted: false,

      // CrowdSale information
      roundNo: 0,
      startTime: 0,
      maxRound: 5,
      // CrowdSale info : BigNumber (wei)
      nMinAmount: BigNumber.from(0),
      nTotalTetherAmount: BigNumber.from(0),
      nTotalTokenAmount: BigNumber.from(0),
      totalSaleCount: 0,

      // CrowdSale Round info/stat
      nTokenPrice: BigNumber.from(0),
      nCoinPrice: BigNumber.from(0),
      nTokenVolume: BigNumber.from(0),
      nSoldTokenVolume: BigNumber.from(0),
      nMaxCoinVolume: BigNumber.from(0),  // calculated
      maxTetherAmount: '',       // calculated
      underSale: false,
      tokenRatio: "",
      soldProgress: 0,
      claimDate: null,
      daysLeft: 0,

      // My Statistics
      myStat: {
          slipCount: 0,
          lastSlip: {},
          tokenAmount: BigNumber.from(0),
          tetherAmount: BigNumber.from(0),
          refCount: 0,
          lastRefSlip: {},
          refAmount: BigNumber.from(0)
      },
    };
  },
  async mounted() {
    let vm = this;

    let refId = sessionStorage.getItem('ref');
    if (refId) {
      vm.refAddress = refId;
      log('RefAddress : %s', refId)
    }

    if (!vm.ethereumDetected) {
      // if the provider is not detected, detectEthereumProvider resolves to null
      // console.error("Please install MetaMask!", this.ethereumDetected);
    } else {
      await vm.initCrowdsale();
      vm.mounted = true;
    }
  },
  methods: {
    ...storeMethods,
    ...walletMethods,
    ...marketMethods,

    async initCrowdsale() {
      let vm = this;

      vm.setSpin(true);
      let { crowdSale } = vm.getContracts(true);
      let { contractNames } = ProjectConstants;

      try {
        await vm.updateSaleInformation();

        // inject token address to wallet
        await vm.addWalletToken(contractAddress[contractNames.stakeToken]);
        await vm.addWalletToken(contractAddress[contractNames.tetherToken]);
        await vm.addWalletToken(contractAddress[contractNames.rewardToken]);
        await vm.updateTokenBalance();

        // get token generation date(in unixtimestamp)
        let startTime = await crowdSale.claimStartTime();
        if (startTime) {
          vm.claimDate = new Date(startTime.toNumber() * 1000);

          if (vm.claimDate.getTime() > Date.now()) {
            vm.daysLeft = dateDiffInDays(new Date() , vm.claimDate)
          }
        }
      }
      catch (e) {
        log('Mounted error : %O', e);
      }

      vm.setSpin(false);
    },

    // setSpin(isSpining) {
    // },
    toShortAddr(addr) {
      return ethUtil.shortAddr(addr);
    },
    getContracts(bConnect) {
      let vm = this;
      let walletProvider = null;
      
      if (['ethereum', 'ropsten', 'kovan', 'rinkeby', 'goerli'].indexOf(ProjectConstants.network) >= 0) {
        walletProvider = new ethers.providers.Web3Provider(window.ethereum, ProjectConstants.network);
      }

      if (walletProvider == null || vm.walletChainId != ProjectConstants.chainId) {
        walletProvider = new ethers.providers.JsonRpcProvider( ProjectConstants.rpcUrl )
      }

      let { contractNames } = ProjectConstants;

      let stakeToken = new ethers.Contract(
        contractAddress[contractNames.stakeToken],
        erc20Artifact.abi,
        walletProvider
      );
      let rewardToken = new ethers.Contract(
        contractAddress[contractNames.rewardToken],
        erc20Artifact.abi,
        walletProvider
      );
      let tetherToken = new ethers.Contract(
        contractAddress[contractNames.tetherToken],
        erc20Artifact.abi,
        walletProvider
      )
      let crowdSale = new ethers.Contract(
        contractAddress[contractNames.crowdSale],
        crowdsaleArtifact.abi,
        walletProvider
      );

      let miningFarm = null;
      if (contractAddress[contractNames.miningFarm]) {
        miningFarm = new ethers.Contract(
          contractAddress[contractNames.miningFarm],
          farmArtifact.abi,
          walletProvider
        );
      }

      if (bConnect && vm.walletAddress) {
        stakeToken = stakeToken.connect(vm.walletAddress);
        rewardToken = rewardToken.connect(vm.walletAddress);
        crowdSale = crowdSale.connect(vm.walletAddress);
        if (miningFarm) miningFarm = miningFarm.connect(vm.walletAddress);
      }

      vm.stakeToken = stakeToken;
      vm.tetherToken = tetherToken;
      vm.rewardToken = rewardToken;
      vm.crowdSale = crowdSale;
      vm.miningFarm = miningFarm;

      return {
        walletProvider,
        stakeToken,
        tetherToken,
        rewardToken,
        crowdSale,
        miningFarm,
      };
    },
    async updateSaleInformation() {
      let vm = this;
      let saleInfo = await vm.crowdSale.querySaleInfo();
      log('Sale Info : %O', toPlain(saleInfo));

      vm.roundNo = saleInfo.roundNo.toNumber() + 1;
      vm.startTime = saleInfo.startTime.toNumber();
      vm.maxRound = saleInfo.maxRound.toNumber();

      // BigNumber (wei)
      vm.nMinAmount = saleInfo.nMinAmount;
      vm.nTotalTetherAmount = saleInfo.nTotalTetherAmount;
      vm.nTotalTokenAmount = saleInfo.nTotalTokenAmount;
      vm.totalSaleCount = saleInfo.totalSaleCount;

      // get current sale prices, volume
      vm.underSale = saleInfo.underSale;

      // // get current sale prices, volume
      // vm.underSale = await vm.crowdSale.queryUnderSale();

      if (vm.underSale) {
        vm.nTokenPrice = saleInfo.nTokenPrice;
        vm.nTokenVolume = saleInfo.nTokenVolume;
        vm.nSoldTokenVolume = saleInfo.nSoldTokenVolume;

        // (vol - sold) = tokenRemained
        // tokenRemained * tokenPrice / coinPrice
        vm.nMaxTetherVolume = vm.nTokenVolume.sub(vm.nSoldTokenVolume).mul(vm.nTokenPrice).div(toBigDec(1));
        vm.maxTetherAmount = utils.formatEther(vm.nMaxTetherVolume);

        vm.tokenRatio = utils.formatEther(vm.nTokenPrice);

        vm.soldProgress = utils.formatEther(
          vm.nSoldTokenVolume.mul(toBigDec(1)).div(vm.nTokenVolume)
        );
        log(`Sale info : USDT Ratio ${vm.tokenRatio}  -- Sold % ${vm.soldProgress}`);
        log('maxTetherAmount : %s', utils.formatEther(vm.nMaxTetherVolume))
        log('nMinAmount : %s', utils.formatEther(vm.nMinAmount))
      }

    },
    ethValue(bn, digits=2) {
      if (bn) {
        try {
          let s = utils.formatEther(bn);
          return Number(s).toLocaleString("en-US", {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
          });
        } catch (e) {
          console.error(e);
        }
      }
      return "0";
    },
    localeValue(s, digits=2) {
      if (s) {
        return Number(s).toLocaleString("en-US", { minimumFractionDigits: digits, maximumFractionDigits: digits, });
      }
      return "0";
    },
    setTetherAmount(r) {
      let vm = this;

      log('Balance : BNB = %s, TETHER = %s , Ratio : %s', vm.walletBalance, vm.tetherBalance, r);

      if (vm.tetherBalance > 0 && r > 0 && r <= 100) {
        vm.tetherAmount = vm.tetherBalance * r / 100;
        vm.adjustTetherAmount();
      }
    },
    adjustTetherAmount() {
      let vm = this;

      if (vm.tetherBalance > 0) {
        if (Number(vm.tetherAmount) > (vm.tetherBalance)) {
          // balance minus GAS Amount
          vm.tetherAmount = vm.tetherBalance;
        }

        if (Number(vm.tetherAmount) > vm.maxTetherAmount) {
          log('Tether Amount : %s  Max Tether Amount : %s', vm.tetherAmount, vm.maxTetherAmount);
          vm.tetherAmount = vm.maxTetherAmount;
        }
        let amt = vm.tetherAmount / vm.tokenRatio;
        // Decimal point to 2
        vm.tokenAmount = Math.floor(amt * 100) / 100;
      }
    },
    async permitUsdt(isContinueBuy) {
      let vm = this;

      vm.setSpin(true);
      try {
        let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        let { tetherToken, crowdSale } = vm.getContracts(true);

        let amount = ethers.utils.parseEther(String(vm.tetherAmount));
        let allowance = await tetherToken.allowance(vm.walletAddress, crowdSale.address);

        if (allowance.gte(amount)) {
          // OK
        }
        else {
          let txApprove = await tetherToken.connect(walletProvider.getSigner()).approve(crowdSale.address, amount);
          let receipt = await txApprove.wait();

          let txId = receipt.transactionHash;
          vm.setSpin(false);

          await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Approve USDT',
            html: `<a href="https://bscscan.com/tx/${txId}" target="bscscan.com">View on BscScan </a>`,
            showConfirmButton: true
          });
        }

        vm.permitted = true;
      }
      catch (e) {
        log('Permit error : %O', e)
      }
      vm.setSpin(false);
    },
    async buyToken() {
      let vm = this;
      let ref = vm.refAddress || vm.walletAddress;
      let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
      let { tetherToken, crowdSale } = vm.getContracts(true);
 
      vm.buyModal = false;

      vm.setSpin(true);
      try {
        let amount = ethers.utils.parseEther(String(vm.tetherAmount));
        // amount = amount.add(toBigDec(0.1));   // add 1 more USDT

        await vm.permitUsdt(true);
        vm.setSpin(true);

        let tx = await crowdSale
          .connect(walletProvider.getSigner())
          .buy(amount, ref);
        let res = await tx.wait();
        let txId = res.transactionHash;
        vm.setSpin(false);

        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Presale token purchased',
          html: `<a href="https://bscscan.com/tx/${txId}" target="bscscan.com">View on BscScan </a>`,
          showConfirmButton: true
        });

        log('buyToken() ----- request : ref=%s , amount:%s',  ref,  String(amount));

        vm.setSpin(true);
        await vm.updateTokenBalance();
        await vm.updateSaleInformation();
      }
      catch (e) {
        console.log('Permit Failed :', e)
      }
      vm.tetherAmount = 0;
      vm.tokenAmount = 0;
      vm.setSpin(false);

    },
    async updateTokenBalance() {
      let vm = this;
      vm.setSpin(true);

      try {
        let { crowdSale } = vm.getContracts(true);
        let myAddr = this.walletAddress;

        if (myAddr) {
          await vm.updateWalletTokenBalance();
          vm.stakeTokenBalance = vm.getTokenBalance(ProjectConstants.tokenName)
          vm.rewardTokenBalance = vm.getTokenBalance(ProjectConstants.rewardTokenName)
          vm.tetherBalance = vm.getTokenBalance(ProjectConstants.tetherTokenName)

          // Account PreSale Statistics
          let purchased = await crowdSale.queryTokenAmount();
          vm.purchasedAmount = utils.formatEther(purchased);

          let stat = await crowdSale.queryStat();
          vm.myStat = vm.convertStat(stat);
          log(`-- My Stat : address = ${vm.walletAddress}\n%O`, vm.myStat);
        }
        else {
          vm.stakeTokenBalance = 0
          vm.rewardTokenBalance = 0
          vm.purchasedAmount = 0
          vm.myStat = {}
        }

        // vm.updateWalletBalance();
      } catch (e) {
        console.error(e);
      }

      vm.setSpin(false);
    },
    convertStat(stat) {
      return {
        slipCount: stat.slipCount.toNumber(),
        referralCount: stat.referralCount.toNumber(),
        tokenAmount: utils.formatEther(stat.tokenAmount),
        tetherAmount: utils.formatEther(stat.tetherAmount),
        referralAmount: utils.formatEther(stat.referralAmount),
        referralPoint: utils.formatEther(stat.referralPoint),
        rewardFixed: utils.formatEther(stat.rewardFixed),
        rewardClaimed: utils.formatEther(stat.rewardClaimed),
        tokenLocked: utils.formatEther(stat.tokenLocked),
        tokenClaimable: utils.formatEther(stat.tokenClaimable),
        tokenClaimed: utils.formatEther(stat.tokenClaimed),
      }
    },

    showReceipt() {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
    },
  },
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
	const utc1 = a.getTime();
	const utc2 = b.getTime();
	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
</script>