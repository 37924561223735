import { BigNumber } from "@ethersproject/bignumber";
import { ethers } from "ethers";

// RAW Encrypt Data
export function toPlain(obj) {
    if (!obj) return null

    let plainObj = {}

    if (obj instanceof Array) plainObj = Object.assign([], obj)
    else {
        if (BigNumber.isBigNumber(obj)) {
            return obj.toString() + ' => ' + ethers.utils.formatEther(obj.toString());
        }
        else plainObj = Object.assign({}, obj)
    }

    for (var key in obj) {
        if (key == 'timestamp') {
            // convert unix timestamp to Date
            plainObj[key] = new Date(obj[key] * 1000);
        }
        else if (BigNumber.isBigNumber(obj[key])) {
            plainObj[key] = obj[key].toString() + ' => ' + ethers.utils.formatEther(obj[key].toString());
        }
        // if (typeof obj[key] === "object") {
        //   plainObj[key] = toPlain(obj[key]);
        // }
        else {
            plainObj[key] = String(obj[key])
        }
    }
    return plainObj;
}

